:root {
  --color-primary: #0e73f6;
  --color-secondary: #ecc94b;
  --color-white: #fff;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dashboard_list li {
  cursor: pointer;
  user-select: none;
}

.trnns-group {
  transition: all 0.3s;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(107, 179, 250, 1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0e73f6;
}
input {
  outline: 0;
  background-color: transparent;
  width: 100%;
  transition: all 0.3s;
}

input:focus {
  opacity: 0.7;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right {
  z-index: 0 !important;
}
button:focus {
  outline: none !important;
}

iframe {
  display: none;
}

/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 44px;
  border-radius: 6px !important;
  outline: none !important;
  border: none !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover {
  outline: none !important;
  border: none !important;
} */
