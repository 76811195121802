//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
body {
  font-family: "Inter", sans-serif;
}

html,
body {
  scroll-behavior: smooth !important;
}

.App {
  text-align: center;
  place-items: center;
  color: red;
  height: 100vh;
  background-color: rgb(54, 54, 54);
}

#react-select-2-input {
  width: 100% !important;
}

.async-select {
  input {
    width: 100% !important;
  }
}

.MuiPagination-ul {
  .MuiButtonBase-root {
    border-radius: 6px;
  }
  .Mui-selected {
    background-color: #0e73f6 !important;
    color: white !important;
  }
}

.a-row {
  display: flex;
  flex-direction: row;
}
.a-col {
  display: flex;
  flex-direction: column;
}

.a-justify-center {
  justify-content: center;
}

.question {
  .question_number {
    display: inline-block;
    padding: 4px;
    margin-right: 8px;
    font-size: 24px;
    line-height: 22px;
    font-weight: 500;
    color: var(--primary-color);
    border-radius: 4px;
    background: rgba(0, 122, 255, 0.1);
  }
  h3 {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
  }
  .desc {
    margin: 0;
    padding: 0 4px;
    font-size: 13px;
    line-height: 22px;
    color: #8d8d8d;
  }
  .answer_list {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 24px;
    list-style-type: none;
    li {
      position: relative;
      min-height: 40px;
      padding: 9px 12px;
      margin-bottom: 12px;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      background-color: #fff;
      font-size: 17px;
      line-height: 22px;
      .shape {
        position: absolute;
        top: 50%;
        right: 32px;
        z-index: 10;
        width: 16px;
        height: 16px;
        border: 2px solid rgba($color: #000000, $alpha: 0.6);
        background-color: #fff;
        transform: translateY(-50%);
        &.circle {
          border-radius: 50%;
        }
        &.rectangle {
          border-radius: 0;
        }
      }
    }
  }
}

.ant-drawer.question-modal .ant-drawer-mask {
  opacity: 0;
  animation: none;
}
.ant-drawer-title {
  text-transform: capitalize;
}

.map-loader {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.radio-btn {
  svg {
    font-size: 16px;
    fill: var(--color-primary);
  }
}

.ReactDragHandleActive {
  background-color: var(--color-primary);
}

.mui-tab-drag {
  .MuiTab-root {
    overflow: initial !important;
  }
}
.clear-animation {
  transition: all 0.3s ease !important;
  &:hover {
    transform: scale(1.5);
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.marquee-text {
  width: 155px !important;
  .marquee {
    margin-right: 8px;
  }
  .overlay {
    height: 0 !important;
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.popover {
  padding: 16px 24px;
  h3 {
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
  }
  button,
  .button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: #000;
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    border-bottom: 1px solid #ebebeb;
    &:last-of-type {
      border-bottom: none;
    }
    .imgWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 56px;
      margin-right: 16px;
      border: 1px solid #ebebeb;
      border-radius: 3px;
    }
    .text {
      text-align: left;
      .title {
        display: block;
        font-size: 14px;
        line-height: 22px;
        color: #000;
      }
      p {
        margin: 0;
        font-size: 12px;
        line-height: 22px;
        color: rgba($color: #000000, $alpha: 0.5);
      }
    }
  }
}

.condition-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0 10px;
    margin-bottom: 12px;
  }
}
.condition-edge {
  .react-flow__edge-path {
    stroke: #ffbd5a !important;
  }
}

.Mui-selected {
  color: var(--color-primary) !important;
}

.cursor-pointer-allow {
  cursor: pointer !important;
}

.cross {
  position: relative;
  display: inline-block;
  &::before,
  &::after {
    content: "";
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
  }
  &::after {
    border-bottom: 2px solid #f76659;
    -webkit-transform: skewY(10deg);
    transform: skewY(4deg);
  }
}

// input[type="number"]::-webkit-outer-spin-button,
// input[type="number"]::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
// input[type="number"] {
//   -moz-appearance: textfield;
// }

.select__input {
  input {
    width: 100% !important;
  }
}

.input-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: block;
  color: #48535b;
}

.MuiTableContainer-root,
.MuiTableCell-root {
  font-family: "Inter", sans-serif !important;
}
.MuiTableRow-root:hover {
  background-color: #f8fafc;
  .MuiTableCell-body {
    &:last-child {
      background-color: #f8fafc !important;
    }
  }
}

.MuiTableCell-body {
  cursor: pointer;
  padding: 10px 16px !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.MuiTableCell-head {
  padding: 10px 16px !important;
  line-height: 0 !important;
  display: flex;
  align-items: center;
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
}

.MuiTable-root {
  .MuiTableRow-head {
    &:last-child {
      .MuiTableCell-head {
        border-bottom: none;
      }
    }
    .MuiTableCell-head {
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .MuiTableRow-root {
    &:last-child {
      .MuiTableCell-body {
        border-bottom: none;
      }
    }
    .MuiTableCell-body {
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}

.orders-table {
  .MuiTableRow-root {
    .MuiTableCell-body {
      &:last-child {
        position: sticky;
        right: 0;
        background-color: #fff;
        box-shadow: inset 1px 0 0 #e5e9eb;
        border-left: none;
      }
      &:nth-child(13) {
        border-right: none;
      }
    }
  }

  .MuiTableRow-head {
    .MuiTableCell-head {
      &:last-child {
        position: sticky;
        right: 0;
        background-color: #fff;
        box-shadow: inset 1px 0 0 #e5e9eb;
        border-left: none;
      }
      &:nth-child(13) {
        border-right: none;
      }
    }
  }
}
.asc,
.desc {
  height: 16px;
  display: flex;
  align-items: center;
}
.asc:after {
  content: " \25B2";
}
.desc:after {
  content: " \25BC";
}

.client-info-column {
  font-weight: 400 !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.MuiPaper-rounded {
  border-radius: 6px !important;
}

th,
td {
  border: 1px solid #e5e9eb;
}

table tr td:first-child,
td:last-child {
  width: 48px;
}

th {
  border-left: 0;
  border-top: 0;
}

textarea,
textarea::-webkit-input-placeholder {
  font-weight: normal;
  font-size: 14px !important;
  line-height: 24px !important;
}

input,
input::-webkit-input-placeholder {
  font-weight: normal;
  font-size: 14px !important;
  line-height: 24px !important;
}
input {
  color: #303940 !important;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #6e7c87 !important;
}
.MuiSlider-thumb{
  color: #0F73F6;
}
.MuiSlider-rail{
  color:#0F73F6;
}
.MuiSlider-track{
  color: #0F73F6;
}