.iconColor-outlined svg {
  fill: #252c32;
}
.iconColor-filled svg {
  fill: #ffffff;
}
.iconColor-text svg {
  fill: #252c32;
}

.button.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}

.icon-button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}
