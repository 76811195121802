

.DashboardLayout {
  display: flex;
  height: 100vh;

  .content-wrapper {
    flex: 1;
    position: relative;
    overflow-y: auto;
  }
}